<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text pb-1">{{
          isBaja ? titleReincorporacion : title
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form2"
          form="form2"
          id="form2"
          @submit.prevent="save()"
        >
          <v-row>
            <!-- Fecha desde -->
            <v-col
              cols="12"
              sm="6"
              :md="growColNombre ? '2' : '3'"
              class="py-0"
            >
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelected"
                    label="Fecha de movimiento"
                    :append-icon="calendarIcon"
                    :loading="loading"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                    outlined
                    dense
                    v-on="on"
                    :rules="rules.required || 'Formato incorrecto'"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="
                    fechaDesdeSelected = formatDate(fechaDesde);
                    loadDatosAfiliado();
                  "
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- N Afiliado -->
            <v-col
              cols="12"
              sm="3"
              md="2"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-text-field
                v-model="numeroAfiliado"
                label="N° de afiliado"
                outlined
                dense
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'###############'"
                @change="cambioAlert(1)"
                autocomplete="off"
                :disabled="disabledGET || checkboxNroAfiliado"
                :filled="disabledGET"
                :readonly="disabledGET"
                class="theme--light.v-input input"
                :rules="
                  !checkboxNroAfiliado
                    ? rules.positiveNumberHigher0.concat(rules.required, [
                        rules.maxLength(numeroAfiliado, 15)
                      ])
                    : []
                "
              >
                <template v-slot:prepend-inner>
                  <v-tooltip bottom>
                    <span>Generar automáticamente</span>
                    <template v-slot:activator="{ on, attrs }">
                      <v-simple-checkbox
                        v-model="checkboxNroAfiliado"
                        :ripple="false"
                        :readonly="true"
                        @input="checkboxNroAfiliado ? cambioAlert(1) : ''"
                        :class="{ enabled: !isDisabled || !disabledGET }"
                        v-on="on"
                        v-bind="attrs"
                      ></v-simple-checkbox>
                    </template>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <!-- Apellido y nombre -->
            <v-col
              cols="12"
              sm="6"
              :md="growColNombre ? '4' : '3'"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-text-field
                v-model="naAfiliado"
                label="Apellido/s y nombre/s"
                filled
                disabled
                readonly
                class="theme--light.v-input input"
                outlined
                dense
                type="text"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <!-- Tipo de documento -->
            <v-col
              cols="12"
              sm="6"
              md="2"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-text-field
                type="text"
                outlined
                dense
                disabled
                filled
                readonly
                class="theme--light.v-input input"
                label="Tipo de documento"
                v-model="tipoDocumentoAfiliado"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <!-- Número de documento -->
            <v-col
              cols="12"
              sm="4"
              md="2"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-text-field
                type="text"
                outlined
                dense
                disabled
                filled
                readonly
                class="theme--light.v-input input"
                label="N° de documento"
                v-model="documentoAfiliado"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- N grupo familiar -->
            <v-col
              cols="12"
              sm="6"
              md="2"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-text-field
                v-model.trim="numeroGRFamiliar"
                label="N° grupo familiar"
                outlined
                @change="cambioAlert(2)"
                :disabled="disabledGET || checkboxNroGrupo"
                :filled="disabledGET"
                :readonly="disabledGET"
                class="theme--light.v-input input"
                dense
                type="text"
                autocomplete="off"
                :rules="
                  !checkboxNroGrupo
                    ? rules.positiveNumberHigher0.concat(rules.required, [
                        rules.requiredTrim(numeroGRFamiliar),
                        rules.maxLength(numeroGRFamiliar, 16)
                      ])
                    : []
                "
              >
                <template v-slot:prepend-inner>
                  <v-tooltip bottom>
                    <span>Generar automáticamente</span>
                    <template v-slot:activator="{ on, attrs }">
                      <v-simple-checkbox
                        v-model="checkboxNroGrupo"
                        :ripple="false"
                        :readonly="true"
                        @input="checkboxNroGrupo ? cambioAlert(1) : ''"
                        :class="{ enabled: !isDisabled || !disabledGET }"
                        v-on="on"
                        v-bind="attrs"
                      ></v-simple-checkbox>
                    </template>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <!-- Parentesco -->
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-select
                v-model="parentescoSelected"
                :items="parentescosCombo"
                item-text="value"
                item-value="id"
                label="Parentesco"
                return-object
                outlined
                clearable
                dense
                no-data-text="Seleccione un parentesco"
                @change="cambioAlert(3)"
                :disabled="disabledGET"
                :filled="disabledGET"
                :readonly="disabledGET"
                class="theme--light.v-input input"
                :rules="this.isDisabled === false ? rules.required : []"
              >
              </v-select>
            </v-col>
            <!-- Convenio-->
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-select
                v-model="convenioSelected"
                :items="convenioCombo"
                item-text="value"
                item-value="id"
                label="Convenio"
                outlined
                return-object
                @change="updatePlan()"
                item-key="id"
                clearable
                dense
                no-data-text="Seleccione un convenio"
                :disabled="banOs || disabledGET"
                :filled="banOs || disabledGET"
                :readonly="banOs || disabledGET"
                class="theme--light.v-input input"
                :rules="this.banOs === false ? rules.required : []"
              >
              </v-select>
            </v-col>
            <!--Plan -->
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="py-0 my-0 d-flex justify-center"
            >
              <v-select
                v-model="planSelected"
                :items="planCombo"
                item-text="value"
                item-value="id"
                label="Plan"
                outlined
                clearable
                return-object
                dense
                no-data-text="Este convenio no posee un plan"
                :disabled="banPlan || banOs || disabledGET"
                :filled="banPlan || banOs || disabledGET"
                :readonly="banPlan || banOs || disabledGET"
                class="theme--light.v-input input"
                :rules="this.planCombo.length > 0 ? rules.required : []"
              >
              </v-select>
            </v-col>
            <!-- SubConvenio-->
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center"
              v-if="createSubconvenio"
            >
              <v-select
                v-model="subConvenioSelected"
                :items="subConvenioCombo"
                item-text="value"
                item-value="id"
                label="Subconvenio"
                outlined
                return-object
                @change="cambioAlert(4)"
                clearable
                dense
                no-data-text="Este convenio no posee un subconvenio"
                :rules="rules.required"
                :disabled="banOs || banSubC || disabledGET"
                :filled="banOs || banSubC || disabledGET"
                :readonly="banOs || banSubC || disabledGET"
                class="theme--light.v-input input"
              >
              </v-select>
            </v-col>
            <!-- Tipo de beneficiario -->
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center"
              v-if="createTipoBenef"
            >
              <v-select
                v-model="tipoBenefSelected"
                :items="tipoBenefCombo"
                item-text="value"
                item-value="id"
                label="Tipo de beneficiario"
                outlined
                clearable
                dense
                no-data-text="Seleccione un tipo de beneficiario"
                :disabled="banTipoBenef || disabledGET"
                :filled="banTipoBenef || disabledGET"
                :readonly="banTipoBenef || disabledGET"
                class="theme--light.v-input input"
              >
              </v-select>
            </v-col>
            <!-- Dependencia-->
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="py-0 my-0 d-flex justify-center"
              v-if="createDependencia"
            >
              <v-select
                v-model="depenSelected"
                :items="depenCombo"
                item-text="value"
                item-value="id"
                label="Dependencia"
                outlined
                clearable
                dense
                no-data-text="Seleccione una dependencia"
                :disabled="banDepen || disabledGET"
                :filled="banDepen || disabledGET"
                :readonly="banDepen || disabledGET"
                class="theme--light.v-input input"
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="py-0 my-0 d-flex justify-center">
              <v-text-field
                v-model="observaciones"
                label="Observaciones"
                outlined
                class="theme--light.v-input input"
                dense
                type="text"
                autocomplete="off"
                :rules="[rules.maxLength(observaciones, 100)]"
              >
              </v-text-field>
            </v-col>
            <!-- integrantes grupo familiar -->
            <v-col
              cols="12"
              sm="6"
              md="10"
              class="py-0 my-0 d-flex justify-center"
            >
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="integrantes"
                item-key="dni"
                class="elevation-1"
                show-select
                :single-select="
                  banDisabledItem ||
                    disabledGET ||
                    disabledNone ||
                    banChangeDatoNoAplicableToFamiliar
                "
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col align-self="center">
                        <span class="primary--text">{{ titleTable }}</span>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.fechaUltMov`]="{ item }">
                  <span>{{ functionFormatDate(item.fechaUltMov) }}</span>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{ isSelected, select }"
                >
                  <v-simple-checkbox
                    v-show="
                      !(
                        banDisabledItem ||
                        disabledGET ||
                        banChangeDatoNoAplicableToFamiliar
                      )
                    "
                    :value="isSelected"
                    :readonly="
                      banDisabledItem ||
                        disabledGET ||
                        banChangeDatoNoAplicableToFamiliar
                    "
                    :disabled="
                      banDisabledItem ||
                        disabledGET ||
                        banChangeDatoNoAplicableToFamiliar
                    "
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="isLoadingBtn"
          form="form2"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirm"
        :openConfirm="openModalSaveConfirm || openModalSaveConfirm3"
        :submitBtn="' SI '"
        :cancelBtn="' NO '"
        @update:openConfirm="cancel"
        @onConfirm="confirmSave"
      />
      <v-dialog
        v-model="openModalSaveConfirm1"
        max-width="400"
        @keydown.esc="cancel"
        persistent
      >
        <v-card>
          <v-card-title class="headline primary--text">{{
            enums.titles.DIALOG_CONFIRMAR
          }}</v-card-title>
          <v-card-text v-if="textConfirm" class="text-left">{{
            textConfirm
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel"> NO </v-btn>
            <v-btn text color="primary" dark @click="confirmSave"> SI </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="openModalSaveConfirm2"
        max-width="400"
        @keydown.esc="cancelEmpresa"
        persistent
      >
        <v-card>
          <v-card-title class="headline primary--text">{{
            enums.titles.DIALOG_CONFIRMAR
          }}</v-card-title>
          <v-card-text v-if="textConfirm2" class="text-left">{{
            textConfirm2
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelEmpresa"> NO </v-btn>
            <v-btn
              text
              color="primary"
              dark
              @click="saveEmpleadorMonotributista"
              >ES MONOTRIBUTISTA</v-btn
            >
            <v-btn text color="primary" dark @click="confirmSaveEmpresa">
              SI
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="openModalAgenteCta"
        v-model="openModalAgenteCta"
        max-width="650px"
        @keydown.esc="closeAndReloadAgenteCta"
        persistent
      >
        <DatosAdicionalesAgenteCuenta
          @closeAndReload="closeAndReloadAgenteCta"
          :afiliado="afiliado"
          :domicilioAgente="domicilioAgente"
          :nroGrupo="numeroGRFamiliar"
          :requestPeriodos="requestPeriodosAgente"
          :periodoDesde="periodoDesde"
          @nextQuestion="nextQuestion"
        />
      </v-dialog>
      <v-dialog
        v-if="openModalMovimientoEmpresa"
        v-model="openModalMovimientoEmpresa"
        max-width="650px"
        @keydown.esc="closeAndReloadEmpresa"
        persistent
      >
        <EditMovimientoEmpresaAfiliado
          @closeAndReload="closeAndReloadEmpresa"
          :benId="this.benId"
          :newEmpresaMonotributista="newEmpresaMonotributista"
          :cargaEmpleadorModal="cargaEmpleadorModal"
          @nextQuestion="nextQuestion"
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DatosAdicionalesAgenteCuenta from "@/components/modules/afiliaciones/afiliados/DatosAdicionalesAgenteCuenta.vue";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import moment from "moment";
import { mask } from "vue-the-mask";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import EditMovimientoEmpresaAfiliado from "./EditMovimientoEmpresaAfiliado.vue";

export default {
  inheritAttrs: false,
  name: "NuevoMovimientoAfiliado",
  directives: { mask },

  props: {
    benId: {
      type: Number,
      required: false
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    },
    isBaja: {
      type: Boolean,
      required: false
    },
    canAddMovimientoPlanesTodos: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    PageHeader,
    ConfirmDialog,
    GoBackBtn,
    DatosAdicionalesAgenteCuenta,
    EditMovimientoEmpresaAfiliado
  },
  data: () => ({
    menu1: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    calendarIcon: enums.icons.CALENDAR,
    fechaDesde: null,
    enums: enums,
    fechaDesdeSelected: null,
    numeroGRFamiliar: null,
    openModalMovimientoEmpresa: false,
    newEmpresaMonotributista: null,
    documentoAfiliado: null,
    tipoDocumentoAfiliado: null,
    rules: rules,
    textConfirm: "",
    textConfirm2: "",
    openModalSaveConfirm: false,
    openModalSaveConfirm1: false,
    openModalSaveConfirm2: false,
    openModalSaveConfirm3: false,
    isFormValid: false,
    parentescosCombo: [],
    openModalAgenteCta: false,
    parentescoSelected: null,
    planCombo: [],
    planSelected: null,
    banChangeDatoNoAplicableToFamiliar: false,
    subConvenioSelected: null,
    subConvenioCombo: [],
    tipoBenefCombo: [],
    tipoBenefSelected: null,
    isDisabled: false,
    depenCombo: [],
    depenSelected: null,
    convenioCombo: [],
    convenioSelected: null,
    selected: [],
    integrantes: [],
    naAfiliado: null,
    numeroAfiliado: null,
    afiliado: {},
    benGrId: null,
    periodoDesde: null,
    requestPeriodosAgente: true,
    planActualAfiliado: null,
    title: "Nuevo movimiento",
    titleReincorporacion: "Reincorporación",
    titleTable: "Aplicar a los integrantes del grupo familiar",
    headers: [
      {
        text: "Documento",
        align: "start",
        sortable: false,
        value: "dni"
      },
      {
        text: "Número afiliado",
        value: "nroAfiliado"
      },
      { text: "Apellido/s y nombre/s", value: "apellidoYNombre" },
      { text: "Parentesco", value: "parentesco" },
      {
        text: "Fecha último movimiento",
        value: "fechaUltMov",
        sortable: false
      },
      { text: "Estado", value: "estadoNombre" }
    ],
    banDepen: false,
    banOs: false,
    banPlan: false,
    banSubC: false,
    banTipoBenef: false,
    // cuando el parametro general venga y que se cambie el convenio.
    banParametroGeneralDisabledItem: false,
    banDisabledItem: false,
    loading: false,
    isTitular: false,
    hasAgente: null,
    banSave: false,
    banCambioGrupoFam: false,
    banCambioParenFam: false,
    banCambioNroGrupoTit: false,
    cargaEmpleadorModal: false,
    hasBonifRecargo: false,
    banCambioBonifRecargo: false,
    mantenerBonifRecargo: false,
    cambioNroGrupoTit: false,
    numeroGRFamiliarOriginal: null,
    empresa: [],
    parentescos: [],
    parentescoSelectedIsTitular: false,
    createTipoBenef: false,
    createDependencia: false,
    createSubconvenio: false,
    entroAgenteCta: false,
    entroEmpresa: false,
    disabledGET: false,
    disabledNone: false,
    estadoBenefId: null,
    datosAnteriores: {},
    growColNombre: false,
    observaciones: "",
    permiteCambiarFechaParentesco: false,
    diasLimiteMovimiento: null,
    fechaDesdeAnterior: null,
    checkboxNroGrupo: false,
    checkboxNroAfiliado: false,
    isLoadingBtn: false
  }),
  created() {
    this.fechaDesdeSelected = this.formatDate(this.fechaConsultaAfi);
    this.fechaDesde = this.fechaConsultaAfi;
    this.loadSelects();
    if (this.isBaja) {
      this.setAlert({
        type: "info",
        message: "El afiliado será reincorporado por baja."
      });
    }
    this.loadDatosAfiliado();
  },
  watch: {
    openModalSaveConfirm1() {
      if (!this.openModalSaveConfirm1 && this.empresa.length == 0) {
        this.openModalSaveConfirm2 = true;
      } else if (!this.openModalSaveConfirm1 && this.empresa.length > 0) {
        this.closeModalCancel();
      }
    },
    openModalMovimientoEmpresa() {
      if (this.entroEmpresa && !this.openModalMovimientoEmpresa) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
    }
  },
  methods: {
    ...mapActions({
      getEstadosBenef: "afiliaciones/getEstadosBenef",
      getConveniosByVigencia: "afiliaciones/getConveniosByVigencia",
      getParentescos: "configAppBenef/getParentescos",
      getAfiliadoById: "afiliaciones/getAfiliadoById",
      getBonificacionesRecargosByBenGrId:
        "afiliaciones/getBonificacionesRecargosByBenGrId",
      hasTitularByBenGrId: "afiliaciones/hasTitularByBenGrId",
      hasAgenteCtaByBenGrId: "afiliaciones/hasAgenteCtaByBenGrId",
      getEmpresasAfiliado: "afiliaciones/getEmpresasAfiliado",
      getParentescos2: "configAfiliaciones/getParentescos",
      getPlanesByConvenioAndVigencia:
        "afiliaciones/getPlanesByConvenioAndVigencia",
      getNuevoMovimiento: "afiliaciones/getNuevoMovimiento",
      getAgenteCuentaByBenGr: "afiliaciones/getAgenteCuentaByBenGr",
      getTiposBenef: "afiliaciones/getTiposBenef",
      getDependenciasBenef: "afiliaciones/getDependenciasBenef",
      getConvenios: "afiliaciones/getConvenios",
      setAlert: "user/setAlert",
      getSubconveniosByConvenio: "afiliaciones/getSubconveniosByConvenio",
      postNuevoMovimiento: "afiliaciones/postNuevoMovimiento",
      getLimiteDiasMovimiento: "afiliaciones/getLimiteDiasMovimiento"
    }),
    closeModal() {
      this.$emit("closeModalNuevoMovimiento");
    },
    cancel() {
      if (this.openModalSaveConfirm3) this.openModalSaveConfirm3 = false;
      if (this.openModalSaveConfirm) {
        this.openModalSaveConfirm = false;
        this.openOtherModal();
      }
      this.nextQuestion();
    },
    cancelEmpresa() {
      this.openModalSaveConfirm2 = false;
      this.closeModalCancel();
    },
    closeModalCancel() {
      if (this.save) {
        this.closeModal();
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
    },

    closeAndReloadAgenteCta() {
      this.openModalAgenteCta = false;
      this.entroAgenteCta = true;
      this.nextQuestion();
    },
    closeAndReloadEmpresa() {
      this.openModalMovimientoEmpresa = false;
      this.entroEmpresa = true;
      this.closeModalCancel();
    },
    confirmSave() {
      if (this.banCambioNroGrupoTit) {
        this.cambioNroGrupoTit = true;
      }
      if (this.banCambioBonifRecargo) {
        this.mantenerBonifRecargo = true;
      }
      if (this.banCambioParenFam && this.banCambioGrupoFam) {
        if (this.entroAgenteCta && this.entroEmpresa) {
          this.openModalAgenteCta = true;
          this.entroAgenteCta = true;
        }
      }
      this.openOtherModal();
    },
    openOtherModal() {
      if (this.banCambioBonifRecargo && this.banCambioNroGrupoTit) {
        this.textConfirm =
          "El titular tiene bonificaciones y/o recargos en su grupo familiar. ¿Desea conservarlos?";
        this.openModalSaveConfirm3 = true;
      }
    },
    confirmSaveEmpresa() {
      this.openModalMovimientoEmpresa = true;
      this.entroEmpresa = true;
      this.cargaEmpleadorModal = true;
    },

    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    async nextQuestion() {
      this.openModalSaveConfirm1 = false;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async loadSelects() {
      const diasLimite = await this.getLimiteDiasMovimiento();
      this.diasLimiteMovimiento = diasLimite;

      const datosParentescos = await this.getParentescos();
      this.parentescosCombo = datosParentescos;

      const datosTiposBenef = await this.getTiposBenef();
      this.tipoBenefCombo = datosTiposBenef;

      const datosDependenciasBenef = await this.getDependenciasBenef();
      this.depenCombo = datosDependenciasBenef;

      const datosConvenios = await this.getConvenios();
      this.convenioCombo = datosConvenios;
    },
    async loadDatosAfiliado() {
      this.loading = true;

      const fecha1 = new Date(this.fechaDesde);
      const fecha2 = new Date(this.fechaConsultaAfi);

      const diff = Math.abs(fecha2 - fecha1);

      // Convertimos la diferencia de milisegundos a días
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

      if (diffDays != 0 && diffDays > this.diasLimiteMovimiento) {
        this.setAlert({
          type: "warning",
          message:
            "La fecha de movimiento excede el límite de días predeterminado"
        });
        this.fechaDesde = this.fechaDesdeAnterior;
        this.fechaDesdeSelected = this.formatDate(this.fechaDesdeAnterior);
        this.loading = false;
        return;
      } else {
        this.setAlert({
          type: "warning",
          message: `Datos actualizados a la fecha de consulta ${this.fechaDesdeSelected}.`
        });
        this.fechaDesdeAnterior = this.fechaDesde;
      }
      let payloaddatosNuevoMovimiento = {
        benId: this.benId,
        date: this.parseDateToIso(this.fechaDesdeSelected)
      };
      const datosNuevoMovimiento = await this.getNuevoMovimiento(
        payloaddatosNuevoMovimiento
      );
      if (datosNuevoMovimiento == null) {
        this.disabledGET = true;
        this.isFormValid = false;
        this.loading = false;
        this.setAlert({
          type: "warning",
          message: "No hay datos para la fecha consultada."
        });
      } else {
        this.datosAnteriores = datosNuevoMovimiento;
        this.disabledGET = false;
        this.isFormValid = true;
        this.naAfiliado = datosNuevoMovimiento.apellidoYNombre;
        this.tipoDocumentoAfiliado = datosNuevoMovimiento.tipoDoc;
        this.documentoAfiliado = datosNuevoMovimiento.dni;
        this.estadoBenefId = datosNuevoMovimiento.estadoBenefId;
        this.numeroAfiliado = datosNuevoMovimiento.nroAfiliado;
        this.numeroGRFamiliar = datosNuevoMovimiento.benGrId.toString();
        this.numeroGRFamiliarOriginal = this.numeroGRFamiliar;
        this.convenioSelected = datosNuevoMovimiento.convenio;
        this.parentescoSelected = datosNuevoMovimiento.parentesco;
        this.tipoBenefSelected = datosNuevoMovimiento.tipoBenId;
        this.depenSelected = datosNuevoMovimiento.dependenciaId;
        this.observaciones = datosNuevoMovimiento.observaciones;
        this.planActualAfiliado = datosNuevoMovimiento.plan?.id ?? null;
        if (this.naAfiliado.length > 22) this.growColNombre = true;
        if (datosNuevoMovimiento.subConvenio.value != null)
          this.createSubconvenio = true;
        if (datosNuevoMovimiento.tipoBenId != -1) this.createTipoBenef = true;
        if (datosNuevoMovimiento.dependenciaId != -1)
          this.createDependencia = true;

        let payloadPlanes = {
          convId: this.convenioSelected.id,
          fechaAlta:
            this.fechaDesde == this.fechaConsultaAfi
              ? this.fechaConsultaAfi
              : this.fechaDesde,
          planActual: this.planActualAfiliado,
          // fechaAlta: this.fechaConsultaAfi,
          incluirNoVig: this.canAddMovimientoPlanesTodos
        };

        const datosPlanesByConvenioAndVigencia = await this.getPlanesByConvenioAndVigencia(
          payloadPlanes
        );
        this.planCombo = datosPlanesByConvenioAndVigencia;
        this.planSelected = datosNuevoMovimiento.plan;
        const datosSubconvenio = await this.getSubconveniosByConvenio(
          this.convenioSelected.id
        );
        this.subConvenioCombo = datosSubconvenio;
        this.subConvenioSelected = datosNuevoMovimiento.subConvenio;

        //integrantes
        this.integrantes = datosNuevoMovimiento.integrantesGrupo;
        this.selected = [];
        if (this.integrantes.length == 0) {
          this.disabledNone = true;
        } else {
          this.disabledNone = false;
        }

        this.loading = false;

        // AFILIADO

        let afi = await this.getAfiliadoById({
          benId: this.benId,
          fechaConsulta: this.fechaConsultaAfi
        });
        this.afiliado = {
          apellido: afi.apellido,
          benGrId: afi.nroGrupo,
          benId: afi.benId,
          cUIL: afi.cuil,
          convenioId: afi.convenioId,
          dNI: afi.dni.toString(),
          dependenciaId: afi.dependenciaId,
          domicilios: afi.domicilios,
          email: afi.email,
          estCivilId: afi.estCivilId,
          fechaAlta: afi.fechaAlta.slice(0, 10),
          fechaNac: afi.fechaNac.slice(0, 10),
          incapacidad: afi.incapacidad,
          legajo: afi.legajo,
          nacId: afi.nacId,
          nombre: afi.nombre,
          nroAfi: afi.nroAfi,
          observaciones: afi.observaciones,
          parId: afi.parId,
          planId: afi.planId,
          sexo: afi.sexo,
          subConvenio: afi.subConvenio,
          tipoBenefId: afi.tipoBenefId,
          tipoDoc: afi.tipoDoc,
          venAfiId: afi.venAfiId
        };
        this.periodoDesde = this.afiliado.fechaAlta;

        // domicilio agente
        // let domicilio = this.afiliado.domicilios.find(x => {
        //   x.tipoRadId === 1;
        // });
        let domicilio = this.afiliado.domicilios[0];

        this.domicilioAgente = {
          barrioId: domicilio.barrioId,
          calle: domicilio.calle,
          codigoPostal: domicilio.codigoPostal.toString(),
          depto: domicilio.depto,
          localidadId: domicilio.localidadId,
          numero: domicilio.numero.toString(),
          piso: domicilio.piso,
          telCelular: domicilio.telCelular,
          telParticular: domicilio.telParticular
        };
        // BANDERAS

        this.banDepen = datosNuevoMovimiento.dependenciaDistinta;
        this.banChangeDatoNoAplicableToFamiliar = false;
        this.banOs = datosNuevoMovimiento.osDistinta;
        this.banPlan = datosNuevoMovimiento.planDistinto;
        this.banTipoBenef = datosNuevoMovimiento.tipoBenefDistinto;
        this.banSubC = datosNuevoMovimiento.subConvenioDistinto;
        this.banParametroGeneralDisabledItem = !datosNuevoMovimiento.permiteCambiarConvenioIntegrante;
        this.banCambioNroGrupoTit = false;
        this.cambioNroGrupoTit = false;
        this.mantenerBonifRecargo = false;
        this.banCambioParenFam = false;
        this.banCambioGrupoFam = false;
        this.banCambioBonifRecargo = false;
        this.permiteCambiarFechaParentesco =
          datosNuevoMovimiento.permiteEditarFechaCambioParentesco;

        if (this.banOs) {
          this.banPlan = this.banOs;
          this.banSubC = this.banOs;
        }

        // veo si es titular
        this.parentescos = await this.getParentescos2();
        if (
          this.parentescos.find(
            element =>
              element.parId == datosNuevoMovimiento.parentesco.id &&
              element.parTit
          )
        ) {
          this.isTitular = true;
          this.parentescoSelectedIsTitular = true;
        }
        //veo si tiene empresa

        this.empresa = await this.getEmpresasAfiliado({
          benId: this.benId,
          fechaConsulta: this.fechaConsultaAfi
        });

        this.hasAgente = await this.hasAgenteCtaByBenGrId(
          this.numeroGRFamiliar > 0 ? this.numeroGRFamiliar : 0
        );
        let recargos = await this.getBonificacionesRecargosByBenGrId(
          this.numeroGRFamiliar
        );
        if (recargos.length > 0) {
          this.hasBonifRecargo = true;
        }
      }
    },
    updatePlan() {
      this.planSelected = null;
      if (this.createSubconvenio)
        this.subConvenioSelected = { id: 0, value: "S/D" };
      if (this.banParametroGeneralDisabledItem) {
        this.banDisabledItem = true;
        this.selected = this.integrantes;
      }
      if (this.convenioSelected != null) {
        this.setPlan();
      }
    },
    async cambioAlert(num) {
      let message = "";
      let hoy = this.formatDate(this.date);
      let validationFechaCambioParentesco = true;
      switch (num) {
        case 1:
          message = `El cambio de número de afiliado, parentesco o número de grupo impacta a partir de hoy.`;
          if (!this.isTitular) {
            this.empresa = await this.getEmpresasAfiliado({
              benId: this.benId,
              fechaConsulta: this.fechaConsultaAfi
            });
          }
          break;
        case 2:
          message = `El cambio de número de grupo, número de afiliado o parentesco impacta a partir de hoy`;
          if (this.numeroGRFamiliar != this.numeroGRFamiliarOriginal) {
            this.changedNumeroGrupo = true;
          }

          if (this.isTitular && this.hasAgente) {
            const response = await this.hasAgenteCtaByBenGrId(
              this.numeroGRFamiliar > 0 ? this.numeroGRFamiliar : 0
            );
            this.hasAgente = response;
            if (!this.hasAgente) {
              this.banCambioNroGrupoTit = true;
            }
          }
          if (this.isTitular && this.hasBonifRecargo) {
            const recargos = await this.getBonificacionesRecargosByBenGrId(
              this.numeroGRFamiliar
            );
            if (recargos.length == 0) {
              this.banCambioBonifRecargo = true;
            }
          }
          if (!this.isTitular) {
            this.hasAgente = await this.hasAgenteCtaByBenGrId(
              this.numeroGRFamiliar > 0 ? this.numeroGRFamiliar : 0
            );
            if (!this.hasAgente) {
              this.banCambioGrupoFam = true;
            }
          }
          break;
        case 3:
          if (!this.permiteCambiarFechaParentesco) {
            message = `El cambio de parentesco, número de afiliado o número de grupo deshabilita cambios al familiar e impacta a partir de hoy.`;
            if (
              this.parentescos.find(
                element =>
                  element.parId == this.parentescoSelected.id && element.parTit
              )
            ) {
              this.parentescoSelectedIsTitular = true;
            } else {
              this.parentescoSelectedIsTitular = false;
            }
            if (!this.isTitular) {
              this.banCambioParenFam = true;
            }
          }
          if (
            this.fechaDesde > this.parseDateToIso(hoy) &&
            this.permiteCambiarFechaParentesco
          ) {
            message = `La fecha de cambio de parentesco debe ser menor o igual a la fecha de hoy`;
            this.fechaDesdeSelected = hoy;
            validationFechaCambioParentesco = false;
          }
          break;
        default:
          message = `El cambio de parentesco, número de afiliado o número de grupo impacta a partir de hoy`;
          break;
      }
      if (!validationFechaCambioParentesco) {
        message = `${message} (${hoy})`;
        this.setAlert({
          type: "warning",
          message: message
        });
      }
      if (
        num != 4 &&
        !this.permiteCambiarFechaParentesco &&
        validationFechaCambioParentesco
      ) {
        message = `${message} (${hoy})`;
        this.setAlert({
          type: "warning",
          message: message
        });
      }
      if (this.banCambioNroGrupoTit) {
        this.textConfirm =
          "El titular tiene un agente de cuenta relacionado. ¿Desea conservar la relación con el Agente?";
        this.openModalSaveConfirm = true;
      }
      if (this.banCambioBonifRecargo && this.openModalSaveConfirm == false) {
        this.textConfirm =
          "El titular tiene bonificaciones y/o recargos en su grupo familiar. ¿Desea conservarlos?";
        this.openModalSaveConfirm3 = true;
      }
    },
    async setPlan() {
      let payloadPlanes = {
        convId: this.convenioSelected.id,
        fechaAlta:
          this.fechaDesde == this.fechaConsultaAfi
            ? this.fechaConsultaAfi
            : this.fechaDesde,
        planActual: this.planActualAfiliado,
        // fechaAlta: this.fechaConsultaAfi,
        incluirNoVig: this.canAddMovimientoPlanesTodos
      };

      const datosPlanesByConvenioAndVigencia = await this.getPlanesByConvenioAndVigencia(
        payloadPlanes
      );
      this.planCombo = datosPlanesByConvenioAndVigencia;

      const datosSubconvenio = await this.getSubconveniosByConvenio(
        this.convenioSelected.id
      );
      this.subConvenioCombo = datosSubconvenio;
    },
    async save() {
      this.isLoadingBtn = true;
      let banderaTitular = false;
      if (
        this.datosAnteriores.convenio == this.convenioSelected &&
        this.datosAnteriores.plan == this.planSelected &&
        this.datosAnteriores.tipoBenId == this.tipoBenefSelected &&
        this.datosAnteriores.dependenciaId == this.depenSelected &&
        this.datosAnteriores.subConvenio == this.subConvenioSelected &&
        !this.banParametroGeneralDisabledItem &&
        !this.isBaja
      ) {
        this.selected = [];
      }
      if (this.parentescoSelectedIsTitular && this.changedNumeroGrupo) {
        const ban = await this.hasTitularByBenGrId(
          parseInt(this.numeroGRFamiliar)
        );
        banderaTitular = ban;
      }
      // Los checkbox agregados al final tambien cuentan como movimientos, que generen automaticamente sus respectivos números
      if (
        this.isBaja === false &&
        this.datosAnteriores.convenio == this.convenioSelected &&
        this.datosAnteriores.plan == this.planSelected &&
        this.datosAnteriores.tipoBenId == this.tipoBenefSelected &&
        this.datosAnteriores.dependenciaId == this.depenSelected &&
        this.datosAnteriores.subConvenio == this.subConvenioSelected &&
        this.datosAnteriores.nroAfiliado == this.numeroAfiliado &&
        this.datosAnteriores.benGrId == this.numeroGRFamiliar &&
        this.datosAnteriores.parentesco == this.parentescoSelected &&
        this.checkboxNroAfiliado == false &&
        this.checkboxNroGrupo == false
      ) {
        this.setAlert({
          type: "warning",
          message: `Si no realiza algún movimiento no se guardarán las observaciones.`
        });
        this.isLoadingBtn = false;
        return;
      }
      if (!this.banSave && banderaTitular) {
        this.setAlert({
          type: "warning",
          message: `El grupo familiar ya tiene un titular. Cambie el parentesco.`
        });
        this.isLoadingBtn = false;
      } else {
        let payloadSave = {
          BenId: this.benId,
          Fecha: this.parseDateToIso(this.fechaDesdeSelected),
          NumeroAfiliado: this.numeroAfiliado,
          GenerarAutomaticamenteNroAfiliado: this.checkboxNroAfiliado,
          Parentesco: this.parentescoSelected,
          NroGrupo: this.numeroGRFamiliar,
          GenerarAutomaticamenteNroGrupo: this.checkboxNroGrupo,
          Convenio: this.convenioSelected,
          Plan: this.planSelected,
          SubConvenio: this.subConvenioSelected,
          TipoBenefId: this.tipoBenefSelected,
          DependenciaId: this.depenSelected,
          OsDistinta: this.banOs,
          PlanDistinto: this.banPlan,
          DependenciaDistinta: this.banDepen,
          TipoBenefDistinto: this.banTipoBenef,
          SubConvenioDistinto: this.banSubC,
          observaciones: this.observaciones,
          IntegrantesGrupo: [
            { benId: this.benId, estadoBenefId: this.estadoBenefId }
          ],
          isBaja: this.isBaja,
          cambioNroGrupoTit: this.cambioNroGrupoTit,
          MantenerBonif: this.mantenerBonifRecargo
        };
        if (this.selected.length > 0) {
          this.selected.forEach(element => {
            payloadSave.IntegrantesGrupo.push({
              benId: element.benId,
              estadoBenefId: element.estadoId
            });
          });
        }
        try {
          const res = await this.postNuevoMovimiento(payloadSave);
          if (res.status === 200) {
            this.banSave = true;
            if (this.banCambioParenFam && this.banCambioGrupoFam) {
              this.textConfirm =
                "El afiliado no tiene un agente de cuenta relacionado para este grupo familiar. ¿Desea crear uno?";
              this.afiliado.parId = this.parentescoSelected.id;
              this.afiliado.benGrId = this.numeroGRFamiliar;
              this.entroAgenteCta = true;
              this.textConfirm2 =
                "El afiliado no tiene una empresa relacionada. ¿Desea crear una para este grupo familiar?";
              this.entroEmpresa = true;
              this.openModalSaveConfirm1 = true;
            } else {
              this.setAlert({ type: "success", message: "Guardado con éxito" });
              this.closeModalCancel();
            }
            this.isLoadingBtn = false;
          }
        } catch {
          this.isLoadingBtn = false;
        }
      }
    },
    async saveEmpleadorMonotributista() {
      const empMon = {
        empId: null,
        locId: this.afiliado.domicilios[0].localidadId,
        empNombre: `${this.afiliado.apellido.trim()} ${this.afiliado.nombre.trim()}`,
        responsable: "",
        cuit: this.afiliado.cUIL,
        direccion: `${this.afiliado.domicilios[0].calle.trim()} ${
          this.afiliado.domicilios[0].numero
        }`,
        telefono: this.afiliado.domicilios[0].telParticular,
        observacion: this.afiliado.domicilios[0].observaciones,
        codPostal: this.afiliado.domicilios[0].codigoPostal,
        email: this.afiliado.email,
        noValidarCorreDDJJ: null,
        ramaId: null
      };
      this.newEmpresaMonotributista = empMon;
      this.openModalMovimientoEmpresa = true;
      this.entroEmpresa = true;
      this.cargaEmpleadorModal = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
.enabled {
  opacity: 1 !important; /* anula la opacidad reducida */
  pointer-events: auto !important; /* permitir eventos del mouse y toque */
}
</style>
