<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ titleAfiAcodif }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="confirmAfiCod()"
        >
          <v-card>
            <v-container>
              <v-card-title>
                <span class="pl-1 primary--text">{{ title }}</span>
              </v-card-title>
              <v-row>
                <!-- Número afiliado -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Número de afiliado"
                    v-model="numeroAfi"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Documento"
                    v-model="documento"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Apellido -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Apellido/s"
                    v-model="apellido"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Nombre -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Nombre/s"
                    v-model="nombre"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-card class="mt-2">
            <v-container>
              <v-card-title>
                <span class="pl-1 primary--text">{{ titleAfiCodificado }}</span>
              </v-card-title>

              <v-row>
                <!-- Número afiliado codificado -->
                <v-col cols="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    disabled
                    readonly
                    label="Apellido codificado"
                    v-model="apellidoCodificado"
                    :filled="apellidoCodificado != null"
                    autocomplete="off"
                  >
                    <template v-slot:append>
                      <v-progress-circular
                        indeterminate
                        size="28"
                        v-if="apellidoCodifLoading"
                        color="primary"
                      ></v-progress-circular> </template
                  ></v-text-field>
                </v-col>
                <div v-if="!apellidoCodificado">
                  <v-col cols="12" class="py-0">
                    <v-menu
                      ref="menu"
                      v-model="menuFecha"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaInicioCoberturaTextField"
                          label="Fecha de inicio de cobertura"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaInicioCobertura = parseDateToIso(
                              fechaInicioCoberturaTextField
                            )
                          "
                          clearable
                          dense
                          outlined
                          autocomplete="off"
                          :rules="rules.required.concat(rules.validDate)"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaInicioCobertura"
                        no-title
                        scrollable
                        @change="
                          fechaInicioCoberturaTextField = formatDate(
                            fechaInicioCobertura
                          );
                          loadDatosAfiliado();
                        "
                        @input="menuFecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </div>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="m-3">
          <v-btn
            color="primary"
            @click="insertarAfiCodificado()"
            :disabled="apellidoCodificado != null"
          >
            Generar afiliado codificado
          </v-btn>
        </div>
        <v-btn outlined @click="closeModalUserCodificado"> Salir </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
export default {
  name: "GestionAfiliadosCodificados",
  directives: { mask },
  props: {
    benId: {
      type: Number,
      required: false
    }
  },
  data: vm => ({
    titleAfiAcodif: enums.titles.GESTION_AFI_CODIFICADO,
    title: "Datos del afiliado",
    titleAfiCodificado: "Datos del afiliado codificado",
    numeroAfi: null,
    isFormValid: false,
    rules: rules,
    documento: null,
    apellido: null,
    apellidoCodifLoading: null,
    apellidoCodificado: null,
    nombre: null,
    calendarIcon: enums.icons.CALENDAR,
    menuFecha: false,
    fechaInicioCoberturaTextField: null,
    fechaInicioCobertura: new Date().toISOString().substr(0, 10)
  }),
  created() {
    this.loadDatosAfiliado();

  },
  methods: {
    ...mapActions({
      getAfiliado: "afiliaciones/getAfiliadoSimpleById",
      setBenApeCodificado: "afiliaciones/setBenApeCodificado",
      generarAfiCodificado: "afiliaciones/generarAfiCodificado",
      setAlert: "user/setAlert"
    }),
    async loadDatosAfiliado() {
      const response = await this.getAfiliado(this.benId);
      this.nombre = response.nombre;
      this.apellido = response.apellido;
      this.numeroAfi = response.nroAfiliado;
      this.documento = response.dni;
      if (response.tipoDoc != null ){this.getAfiliadoCodificado()}
      else {
         this.setAlert({
          type: "warning",
          message: "Debe configurar el tipo de documento para generar el código."
        });
      }
    },
    async getAfiliadoCodificado() {
      this.apellidoCodifLoading = true;
      const response = await this.setBenApeCodificado(this.benId);
      this.apellidoCodificado = response != null ? response.codigo : null;
      this.apellidoCodifLoading = false;
      // this.fechaInicioCoberturaTextField = this.formatDate(response.fechaCobertura)
    },
    async insertarAfiCodificado() {
      if(this.fechaInicioCoberturaTextField === null){
        this.setAlert({
          type: "warning",
          message: "Por favor ingrese fecha de inicio de cobertura"
        });
      }else {
      this.apellidoCodifLoading = true
      const data = {
        benId: this.benId,
        fechaCobertura: this.fechaInicioCoberturaTextField
      };
      const res = await this.generarAfiCodificado(data);
      if (res.status === 200) {
        this.getAfiliadoCodificado();
          this.apellidoCodifLoading = false
        this.setAlert({
          type: "success",
          message: "Afiliado codificado generado exitosamente"
        });
        
      }
      }
    },

    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeModalUserCodificado() {
      this.$emit("closeModalUserCodificado");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
